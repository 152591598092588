<template>
  <div :class="[$style.documents, $style[$route.meta.indexClass]]">
    <div :class="$style.title">
      <Back
        :redirects="[
          {
            from: 'DocumentsProcessCreateFromTemplate',
            to: 'DocumentsTemplatesList',
          },
        ]"
        :hide-for="['DocumentsProcessesList']"
      />

      <component
        :is="titleComponent"
        :title="title"
      />
    </div>

    <router-view />
  </div>
</template>

<script>
import { mapWritableState } from 'pinia';
import Back from '@/components/back.vue';
import Title from '@/components/title.vue';
import { useProcessesStore } from '@/stores/index.js';

export default {
  components: {
    Back,
    Title,
  },
  computed: {
    ...mapWritableState(useProcessesStore, ['shortUserId']),

    title() {
      return this.$route.meta?.title;
    },
    titleComponent() {
      const component = this.$route.meta?.titleComponent;

      if (component) {
        return component;
      }

      return Title;
    },
  },
  beforeDestroy() {
    this.shortUserId = null;
  },
};
</script>

<style lang="scss" module>
.documents {
  .title {
    display: flex;
    align-items: baseline;
    padding-bottom: 25px;
    min-height: 57px;
  }

}
@media (max-width: 768px) {
  .documents {
    &--trial-subscription {
      justify-content: center;
    }
  }
}
</style>
