<template>
  <div
    :class="$style.title"
  >
    <div
      :class="$style.text"
      :title="title"
    >
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" module>
.title {
  .text {
    font-size: 24px;
    line-height: 32px;
    word-break: break-all;
    color: var(--color-main);
  }
}
</style>
